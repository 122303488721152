
/* SCROLL ANIMATION */
/* .stickyContentStyle {
  display: flex;
  position: sticky;
  top: 0;
  flex-direction: row;
  width: 100%;
  height: 100vh;
  padding-left: 50px;
  align-items: center;
}
*/

.stickyContentStylePhone {
  display: flex;
  position: sticky;
  flex-direction: column;
  top: 0;
  height: 45vh;
  justify-content: center;
  width: 100%;
  align-items: center;
  padding-top: 195px;
  padding-bottom: 120px;
}

@media (min-width: 700px) {
  .stickyContentStylePhone {
    padding-top: 200px;
  }
}


/* .stickyContentStylePhone {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-left: 0;
  align-items: center;
  position: relative;
  padding-top: 40px;
} */

.stickyContentStyle {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  position: sticky;
  top: 0;
  height: 100vh;
  padding-left: 50px;
}











.animationContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Or space-around, space-evenly, etc. */
  gap: 15px; /* Spacing between the components */
  z-index: 99;
}

.animationTextStyle {
  width: 70%;
  padding-left: 40px;
  padding-right: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@media (max-width: 1200px) {
  .animationTextStyle {
    width: 100%;
    padding-left: 40px;
    padding-right: 40px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
@media (max-width: 800px) {
  .animationTextStyle {
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}


.animationImageContainer {
  display: flex;
  justify-content: center;
  max-width: 600px;
  width: 100%;
  /* height: 100%; */
}

@media (max-width: 1400px) {
  .animationImageContainer {
    padding-top: 5%;
    max-width: 450px;
    padding: 0px 0px;
  }
}

@media (max-width: 1200px) {
  .animationImageContainer {
    width: 85%;
    /* max-width: 500px; */
    padding: 0px 0px;
    padding-top: 5%;
  }
}

@media (max-width: 600px) {
  .animationImageContainer {
    padding-top: 8%;
    width: 100%;
  }
}
