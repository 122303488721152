.column-container {
  display: flex;
  margin-top: 50px;
}

.left-column {
  flex: 1;
  padding: 20px;
  position: 'relative',

}

.right-column {
  /* display: flex; */
  flex: 1;
  /* height: 100vh; */
  padding: 20px;
  position: sticky;
  top: 15%;
  align-self: flex-start;
  width: 50%;
}

.right-column img {
  width: 100%;
  height: auto;
}

.paragraph {
  padding-bottom: 26vh;
  padding-top: 26vh;
}
@media (max-width: 920px) {
  .paragraph {
    padding-bottom: 5vh;
    padding-top: 5vh;
  }
}

.btnContainer {
  margin: 60px 5px;
  align-items: center;
  margin-bottom: 30px;
}


.total-container {
  margin-top: 50px;
  max-width: 1300px;
  margin: auto;
}


.textSectionTitle {
  margin-left: 80px;
  margin-right: 80px;
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: left;
  /* color: #53595D; */
  font-size: 2.25rem;
  font-weight: normal;
  line-height: 1.5;
}

.textSectionContent {
  margin-left: 60px;
  margin-right: 60px;
  margin-bottom: 10px;
  /* color: #53595D; */
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.5;
  text-align:  justify;
}

@media (max-width: 920px) {
  .textSectionContent {
    margin-left: 2px;
    margin-right: 2px;
  }
}


.fade-in {
  opacity: 1;
}

.fade-out {
  opacity: 0;
}


@media (max-width: 920px) {
  .button-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 20px;
  }

  .mobile-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 70px;
  }

  .mobile-section {
    width: 90%;
    max-width: 600px;
    margin-bottom: 40px;
    padding: 0;
  }

  .mobile-image {
    width: 90%;
    height: auto;
    margin-bottom: 15px;
    /* box-shadow: 0 4px 16px 0 rgba(0,0,0,0.4); */
  }

  .mobile-text {
    text-align: left;
  }

  .mobile-text p {
    margin: 0;
  }

  .textSectionTitle {
    margin-left: 0px;
    margin-right: 0px;
    margin-top: 0px;
    margin-bottom: 0px;
    text-align: center;
    font-size: 2rem;
    font-weight: normal;
    line-height: 1.5;
  }

  .centreImage {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

}
