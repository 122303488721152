.slider-container {
  position: relative;
}

.slider-wrapper {
  position: relative;
  width: 80%;        /* Set width to 80% */
  max-width: 100%;   /* Ensure it doesn't exceed 100% */
  margin: 0 auto;    /* Center horizontally */
  height: 100%;      /* Maintain full height */
  padding-top: 1.85rem;
  padding-bottom: 4rem;
  overflow: hidden;
}

@media (max-width: 600px) {
  .slider-wrapper {
    width: 100%;   /* Full width on small screens */
  }
}

.keen-slider {
  width: 100%;
  /*max-width: 1400px; */  /* Limit the maximum width of the slider */
  height: 100%;       /* Ensure the slider height adapts to its content */
  margin: 0 auto;
  overflow: visible!important;    
  position: static;
  top: 1rem;
}


.keen-slider__slide {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  transition: transform 0.7s ease;
  overflow: visible!important;  /* Ensure content does not overflow beyond the container */
  width: 100%;       /* Make sure slides take full width of the container */
  height: 100%;
  box-sizing: border-box;
}

.slide-content {
  width: 100%;       /* Ensure the content inside the slide scales to fit */
  max-width: 90%;    /* Add padding to avoid overflowing */
  height: 100%;      /* Make the slide-content take the full height of the slide */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: transform 1.2s ease;
  overflow: visible!important;
}

.slide-content img {
  max-width: 100%;       /* Ensure the image takes no more than 100% of the width */
  height: auto;          /* Maintain aspect ratio */
  object-fit: contain;   /* Make sure the image fits inside the container */
}

.slider-item:not(.slider-item--active):not(.slider-item--prev):not(.slider-item--prev-2):not(.slider-item--next):not(.slider-item--next-2) .slide-content {
  transform: scale(0.7) translateY(100%);
}

.slider-item--active .slide-content {
  transform: rotate(0deg) scale(1.2) translateY(0);
}

.slider-item--prev .slide-content {
  transform: rotate(-5deg) scale(0.85) translateY(10%);
}

.slider-item--prev-2 .slide-content {
  transform: rotate(-25deg) scale(0.8) translateY(40%);
}

.slider-item--next .slide-content {
  transform: rotate(15deg) scale(0.85) translateY(10%);
}

.slider-item--next-2 .slide-content {
  transform: rotate(35deg) scale(0.8) translateY(40%);
}


/* Pagination bullets */
.slider-pagination {
  display: flex;
  justify-content: center;
  margin-top: 5px;
  margin-bottom: 25px;
}

.slider-pagination-bullet {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  border: 2px solid grey;
  background-color: transparent;
  margin: 0 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.slider-pagination-bullet.active {
  background-color: grey; /* Active bullet fill */
}

.slider-pagination-bullet:hover {
  background-color: lightgrey; /* Slight color change on hover */
}


/* Arrow button styles */
.arrow-button {
  position: absolute !important; /* Ensure this is present */
  top: 50% !important;           /* Vertically center */
  transform: translateY(-50%) !important;
  z-index: 10 !important;

  /* Appearance */
  background-color: rgba(0, 0, 0, 0.6) !important;
  color: white !important;
  border-radius: 50% !important;
  width: 50px;
  height: 50px;

  /* Center the icon inside the button */
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;

  /* Transition for hover effect */
  transition: background-color 0.3s ease !important;
}

.arrow-button:hover {
  background-color: rgba(0, 0, 0, 1) !important;
}

/* Disabled state */
.arrow-button:disabled {
  opacity: 0.5;
  cursor: default;
}

/* Left Arrow Button Positioning */
.arrow-button--left {
  left: 0px;
}

/* Right Arrow Button Positioning */
.arrow-button--right {
  right: 0px;
}

@media (min-width: 768px) {
  .arrow-button--left {
    left: 5%;   /* Full width on small screens */
  }
  .arrow-button--right {
    right: 5%;   /* Full width on small screens */
  }
}

@media (min-width: 1200px) {
  .arrow-button--left {
    left: 15%;   /* Full width on small screens */
  }
  .arrow-button--right {
    right: 15%;   /* Full width on small screens */
  }
}

