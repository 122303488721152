.globalContainer {
  max-width: 100%;
  margin: 2px;
  padding: 2px;
  /* overflow-x: hidden; */
}

.contentContainer {
  margin: 0 auto;
  padding: 2px 8px;
  max-width: 1200px;
  position: relative;
  padding-top: 1px;
  padding-bottom: 20px;
  height: auto;
}

.header1 {
  font-size: 3rem;
  font-weight: 400;
}
@media (max-width: 920px) {
  .header1 {
    font-size: 2.3rem;
  }
}

.header2 {
  font-size: 1.65rem;
}

.largeText {
  font-size: 1.65rem;
}
@media (max-width: 920px) {
  .largeText {
    font-size: 1.45rem;
  }
}

.mediumText {
  font-size: 1.45rem;
}
@media (max-width: 920px) {
  .mediumText {
    font-size: 1.2rem;
  }
}

.smallText {
  font-size: 1.3rem;
}
@media (max-width: 920px) {
  .smallText {
    font-size: 1.1rem;
  }
}




.whiteGreyBackground {
  background: linear-gradient(to right, white 50%, #F6F6F6 50%);
  color: black;
  margin-bottom: 0;
  margin-top: 0;
}

@media (max-width: 920px) {
  .whiteGreyBackground {
    background: linear-gradient(to right, white 50%, white 50%);
  }
}

.greyWhiteBackground {
  background: linear-gradient(to right, #F6F6F6 50%, white 50%);
  color: black;
  margin-bottom: 0;
  margin-top: 0;
}

@media (max-width: 920px) {
  .greyWhiteBackground {
    background: linear-gradient(to right, #F6F6F6 50%, #F6F6F6 50%);
  }
}

.mainHeader {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 2.85rem;
  font-weight: normal;
  /* color: #53595D; */
  flex-direction: column;
}

.InfoText {
  font-size: 1.3rem;
  /* color: #53595D; */
  line-height: 1.5;
  margin: 20px 12px;
  max-width: 1200px;
}
@media (max-width: 1250px) {
  .InfoText {
    margin: 20px 25px;
  }
}
@media (max-width: 800px) {
  .InfoText {
    font-size: 1.1rem;
    margin: 20px 15px;
  }
}

.textAlignJustify {
  text-align:  justify;
}

.textAlignJustifySmall {
  @media (max-width: 920px) {
    text-align:  justify;
  }
}


/* .btnContainer {
  margin: 35px 5px;
  align-items: center;
  margin-bottom: 30px;
}

@media (min-width: 920px) {
  .btnContainer {
    margin-top: 50px;
  }
}
 */

.modelCTimg {
  max-width: 40%;
  margin: 50px 0;
}
@media (max-width: 1100px) {
  .modelCTimg {
    max-width: 60%;
  }
}
@media (max-width: 600px) {
  .modelCTimg {
    max-width: 72%;
    margin: 35px 0;

  }
}

.lowerMargin {
  margin-bottom: 50px;
}
@media (max-width: 920px) {
  .lowerMargin {
    margin-bottom: 25px;
  }
}

