/* scrollProductsTextWrapper */
.scrollProductsTextWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* scrollProductsParagraph */
.scrollProductsParagraph {
  text-align: center;
  font-size: 0.75rem;
  margin: 0 0.5rem;
  margin-bottom: 25px;
  color: white;
}

@media (min-width: 620px) {
  .scrollProductsParagraph {
    text-align: justify;
    font-size: 1.2rem;
    margin: 0 2rem;
  }
}

@media (min-width: 1400px) {
  .scrollProductsParagraph {
    font-size: 1.3rem;
  }
}

/* bgImageContainer */
.bgImageContainer {
  display: flex;
  background-color: #b6b7b9;
  width: 100%;
  align-items: center;
  /* Optional: Set a min-height if needed */
  /* min-height: 400px; */
}


/* homeImage */
.homeImage {
  width: 600px;
  height: auto;
  flex-shrink: 0;
  margin-right: 20px;
}

@media (max-width: 700px) {
  .bgImageContainer {
    flex-direction: column;
  }
  .homeImage {
    width: 100%;
    height: auto;
    margin-left: 20px;
  }
}

/* imageOverlayText */
.imageOverlayText {
  flex: 1;
  padding: 3% 3%;
  color: white;
}

/* header1 */
.imageOverlayText .header1 {
  letter-spacing: 1.1px;
  line-height: 1.5;
  font-size: 4rem;
  font-family: 'DM Sans', sans-serif;
}

@media (max-width: 1400px) {
  .imageOverlayText .header1 {
    font-size: 3.6rem;
  }
}

@media (max-width: 900px) {
  .imageOverlayText .header1 {
    font-size: 3.4rem;
  }
}

@media (max-width: 700px) {
  .imageOverlayText .header1 {
    font-size: 3rem;
    text-align: center;
  }
}

/* header2 */
.imageOverlayText .header2 {
  letter-spacing: 1.1px;
  line-height: 1.5;
  font-size: 2.1rem;
  font-family: 'DM Sans', sans-serif;
}

@media (max-width: 1400px) {
  .imageOverlayText .header2 {
    font-size: 1.7rem;
  }
}

@media (max-width: 900px) {
  .imageOverlayText .header2 {
    font-size: 1.5rem;
  }
}

@media (max-width: 700px) {
  .imageOverlayText .header2 {
    font-size: 1.3rem;
    text-align: center;
  }
}

/* header3 */
.header3 {
  z-index: 2;
  width: 100%;
  letter-spacing: 1.1px;
  line-height: 1.5;
  font-size: 2.1rem;
  font-family: 'DM Sans', sans-serif;
}

@media (max-width: 920px) {
  .header3 {
    font-size: 1.7rem;
  }
}

@media (max-width: 600px) {
  .header3 {
    font-size: 1.6rem;
    text-align: center;
  }
}



/* sectionContainer */
.sectionContainer {
  max-width: 1400px;
  margin: auto;
  padding: 16px;
}

/* textCentreConatiner */
.textCentreContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* splitContainer1 */
.splitContainer1 {
  margin: auto;
  display: flex;
  justify-content: center;
  margin-top: 5px;
  align-items: center;
}

/* splitContainer2 */
.splitContainer2 {
  width: 95%;
  margin: auto;
  display: flex;
  justify-content: center;
  margin-top: 5px;
  flex-direction: column;
}

/* swipeDisplayCage */
.swipeDisplayCage {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30%;
  margin-right: 20px;
}

@media (max-width: 600px) {
  .swipeDisplayCage {
    margin-right: 5px;
    width: 40%;
  }
}

/* swipeDisplayCageLeft */
.swipeDisplayCageLeft {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30%;
  margin-left: 20px;
}

@media (max-width: 600px) {
  .swipeDisplayCageLeft {
    margin-left: 5px;
    width: 40%;
  }
}

/* swipeTitle */
.swipeTitle {
  text-align: center;
  font-size: 1rem;
  font-weight: bold;
  color: white;
  margin: 8px 0;
}

@media (min-width: 600px) {
  .swipeTitle {
    font-size: 1.7rem;
    margin: 16px 0;
  }
}

@media (min-width: 960px) {
  .swipeTitle {
    font-size: 1.8rem;
  }
}

.swipeCageLabel {
  text-align: center;
  font-size: 1.5rem;
  font-weight: bold;
  margin: 16px 0;
  color: white;
}



.swiperProductsContainer {
  background-color: #A8A8A8;
  height: 100%;
  width: 75%;
  max-width: 900px;
  display: flex;
  flex-direction: row;
  border-radius: 17px;
}

@media (max-width: 1100px) {
  .swiperProductsContainer {
    width: 85%;
  }
}

@media (max-width: 920px) {
  .swiperProductsContainer {
    width: 94%;
  }
}

.swiperProductsSlide {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiperTestimonyContainer {
  height: 100%;
  width: 94%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 30px;
  padding: 20px 15px;
  padding-top: 30px;
  margin: 0px 40px;
}

@media (max-width: 1200px) {
  .swiperTestimonyContainer {
    gap: 0px;
    margin: 0px 0px;
    padding: 20px 0px;    
  }
}

@media (max-width: 920px) {
  .swiperTestimonyContainer {
    flex-direction: column;
    margin: 0px 0px;
    padding: 20px 0px;
    padding-bottom: 25px;
    gap: 15px;
    flex-wrap: nowrap;
    height: 100%;
  }
}

.testimonyText {
  text-align: justify;
  max-width: 30%;
  margin: 10px 20px;
  flex-grow: 1;
}
@media (max-width: 1200px) {
  .testimonyText {
    margin: 5px 5px;
    max-width: 31%;
  }
}
@media (max-width: 920px) {
  .testimonyText {
    max-width: 100%;
    margin: 0px 0px;
  }
}

.swiperTestimony .swiper-button-next, 
.swiperTestimony .swiper-button-prev {
  color: white;
  margin:10px;
  width: 50px;
  height: 100%;
  top: 0%; 
  font-weight: bold;
  @media (max-width: 1200px) {
    display: none;
  }
}

/* If the arrows are SVGs, this will change their fill color to white */
.swiperTestimony .swiper-button-next svg,
.swiperTestimony .swiper-button-prev svg {
  fill: white;
}

/* Styling for all pagination dots */
.swiperTestimony .swiper-pagination-bullet {
  border: solid;
  border-width: 2px;
  width: 11px;
  height: 11px;
  border-color: rgb(113, 122, 136);
  background-color: rgb(113, 122, 136);
  box-sizing: border-box;
  margin: 0 14px;
  @media (max-width: 920px) {
    width: 9px;
    height: 9px;
  }
}

/* Styling for the active pagination dot */
.swiperTestimony .swiper-pagination-bullet-active {
  background-color: rgb(113, 122, 136);
  width: 13px;
  height: 13px;
  transform: translateY(1px);
  @media (max-width: 1200px) {
    width: 11px;
    height: 11px;
  }
}

.swiperTestimony .swiper-slide {
  padding-bottom: 40px;
}

@media (max-width: 920px) {
  .swiperTestimony {
    margin-top: 25px;
  }
}



.swiperProducts .swiper-button-next,
.swiperProducts .swiper-button-prev {
  color: rgb(84, 89, 93);
  margin-left:18%;
  margin-right:18%;
  font-weight: bold;
  @media (max-width: 1600px) {
    margin-left:12%;
    margin-right:12%;
  }
  @media (max-width: 1350px) {
    margin-left:6%;
    margin-right:6%;
  }
  @media (max-width: 1100px) {
    margin-left:2%;
    margin-right:2%;
  }
  @media (max-width: 920px) {
    display: none;
  }
}

/* If the arrows are SVGs, this will change their fill color to white */
.swiperProducts .swiper-button-next svg,
.swiperProducts .swiper-button-prev svg {
  fill: rgb(84, 89, 93);
}

/* Styling for all pagination dots */
.swiperProducts .swiper-pagination-bullet {
  background-color: rgba(255, 255, 255, 0.5); /* Non-active dots (change color as needed) */
  width: 8px;
  height: 8px;
}

/* Styling for the active pagination dot */
.swiperProducts .swiper-pagination-bullet-active {
  background-color: white; /* Active dot */
  width: 10px;
  height: 10px;
  transform: translateY(1px);
}


.patientInMind {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
  padding-bottom: 95px;
}

.benefitsImages {
  max-width: 300px;
}

.benefitsContainer {
  align-items: center;
  margin-bottom: 30px;
  gap: 100px;
  justify-content: space-between;
}

@media (max-width: 1200px) {
  .benefitsContainer {
    gap: 25px;
  }
}

@media (max-width: 920px) {
  .benefitsContainer {
    gap: 10px;
  }
}

